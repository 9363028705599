<ng-container *ngIf="!isLoading">
    <!-- Certificate Button -->
    <div
        class="flex justify-end mt-8"
        *ngIf="user.role_type === 'student' && disciplineDetail?.certificate_id"
    >
        <button
            (click)="emitCertificate(disciplineDetail?.certificate_id)"
            class="bg-transparent text-customBlue-royal font-semibold py-1 px-4 rounded border-2 border-customBlue-royal"
        >
            <p>{{ i18n.disciplines_overview_certificate }}</p>
        </button>
    </div>

    <!-- Feed -->
    <div class="rounded-lg mt-8" *ngIf="false">
        <!--content-->
        <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-customWhite-default outline-none focus:outline-none"
        >
            <!--header-->
            <div
                class="text-start p-5 border-b border-solid border-customBlue-light rounded-t flex items-center justify-between"
            >
                <h3 class="text-xl font-bold text-customBlue-royal">
                    {{ i18n.disciplines_overview_course_feed }}
                </h3>
            </div>
            <!--body-->
            <div class="p-6 discipline-box">
                <ng-container *ngIf="disciplineDetail?.syllabus.length > 0">
                    <div
                        *ngFor="let feed of disciplineDetail?.syllabus"
                        class="flex items-center justify-start m-2 p-2 rounded bg-feed"
                    >
                        <div class="flex items-center">
                            <!-- <div [ngSwitch]="true">
                            <svg *ngSwitchCase="feed?.icon == 'class'" width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.514 18.6667H21V2.33333C21 1.71449 20.7542 1.121 20.3166 0.683417C19.879 0.245833 19.2855 0 18.6667 0H3.5C2.093 0 0 0.932167 0 3.5V19.8333C0 22.4012 2.093 23.3333 3.5 23.3333H21V21H3.514C2.975 20.986 2.33333 20.7725 2.33333 19.8333C2.33333 18.8942 2.975 18.6807 3.514 18.6667ZM5.83333 4.66667H16.3333V7H5.83333V4.66667Z" fill="#233674"/>
                            </svg>
                        </div> -->
                            <div>
                                <app-content-types-icons
                                    class="content-types-icons"
                                    [type]="feed?.type"
                                ></app-content-types-icons>
                            </div>
                            <span class="ml-2">{{ feed?.title }}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="disciplineDetail?.syllabus.length === 0">
                    <div class="flex">
                        <div class="text-customBlue-default">
                            {{ i18n.disciplines_overview_no_message_yet }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Ementa and Participants -->
    <div class="mt-8 grid xl:grid-cols-2 md:grid-cols-1 gap-4">
        <!-- Ementa  -->
        <div
            class="relative border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-customWhite-default outline-none focus:outline-none"
        >
            <!-- Button  -->
            <button
                type="button"
                (click)="toggleModalEmenta()"
                *appCheckPermissions="'disciplines'; type: 'edit'"
                class="absolute right-6 p-2 bg-customBlue-royal text-customWhite-default rounded-full text-18"
                [style.top]="'-15px'"
            >
                <span class="iconify-inline" data-icon="akar-icons:edit"></span>
            </button>
            <!--header-->
            <div
                class="text-start p-5 border-b border-solid border-customBlue-light rounded-t"
            >
                <h3 
                    class="text-xl font-bold text-customBlue-royal"
                    style="
                        line-height: 38px;
                        margin: auto;
                    "
                >
                    {{ i18n.disciplines_overview_course_program }}
                </h3>
            </div>
            <!--body-->
            <div class="p-6 discipline-box">
                <div
                    class="text-customGray-dark break-words"
                    #descriptionText
                    [innerHTML]="disciplineDetail?.description || '' | safeHTML"
                ></div>
                <!-- Empty -->
                <ng-container *ngIf="disciplineDetail?.description === ''">
                    <div class="flex">
                        <div class="text-customBlue-default">
                            {{ i18n.disciplines_overview_no_program_yet }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Participantes  -->
        <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-customWhite-default outline-none focus:outline-none"
        >
            <!--header-->
            <div
                class="text-start flex items-center justify-between p-5 border-b border-solid border-customBlue-light rounded-t"
            >
                <h3 class="text-xl font-bold text-customBlue-royal">
                    {{ i18n.disciplines_overview_participants }}
                </h3>
                <div *ngIf="isNotStandalone">
                    <button
                        type="button"
                        (click)="displayPanel.toggle()"
                        *ngIf="
                            disciplineDetail.general_settings?.gmail ||
                            disciplineDetail.general_settings?.google_chat
                        "
                        class="border border-customBlue-royal rounded text-customBlue-royal font-bold py-1.5 px-8 relative"
                    >
                        {{ i18n.disciplines_settings_certificates_options }}

                        <app-display-panel
                            #displayPanel
                            [style]="{
                                right: '22px',
                                top: '54px',
                                'z-index': '7'
                            }"
                            [newStyle]="{ width: 'fit-content !important' }"
                            [arrow_deslocation]="'100%'"
                            [custom_radius]="'10px'"
                            [lockWidth]="false"
                        >
                            <div class="flex flex-col gap-1">
                                <div class="flex gap-1 items-center">
                                    <div class="p-1 w-7 h-7">
                                        <img
                                            src="./assets/svg/icon-mail.svg"
                                            alt="Mail"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        *ngIf="
                                            disciplineDetail.general_settings
                                                ?.gmail
                                        "
                                        (click)="toggleModalSend('mail')"
                                        class="whitespace-nowrap p-1 text-customBlue-default font-medium"
                                    >
                                        {{ i18n.disciplines_send_gmail }}
                                    </button>
                                </div>

                                <ng-container
                                    *ngIf="
                                        !hasGoogleSpaceCreated &&
                                        disciplineDetail.belongs_domain
                                    "
                                >
                                    <div
                                        *appCheckPermissions="
                                            'googlechat';
                                            type: 'create'
                                        "
                                        class="flex gap-1 items-center"
                                    >
                                        <div class="p-1 w-7 h-7">
                                            <img
                                                src="./assets/svg/icon-chat.svg"
                                                [alt]="
                                                    i18n.disciplines_create_google_chat
                                                "
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            (click)="callGoogleSpace()"
                                            class="whitespace-nowrap p-1 text-customBlue-default font-medium"
                                        >
                                            {{
                                                i18n.disciplines_create_google_chat
                                            }}
                                        </button>

                                        <div class="content-tooltip">
                                            <span
                                                class="iconify-inline text-18 text-customGray-default"
                                                data-icon="carbon:help"
                                            ></span>
                                            <div
                                                class="w-80 absolute bg-customYellow-default p-2 rounded-md shadow-custom tooltip opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                                            >
                                                <span
                                                    class="text-center text-14 text-customGray-dark font-Roboto font-normal"
                                                >
                                                    {{
                                                        i18n.disciplines_space_tooltip_space
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="hasGoogleSpaceCreated">
                                    <div class="flex gap-1 items-center">
                                        <div class="p-1 w-7 h-7">
                                            <img
                                                src="./assets/svg/icon-chat.svg"
                                                [alt]="
                                                    i18n.disciplines_create_google_chat
                                                "
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            (click)="callGoogleSpace()"
                                            class="whitespace-nowrap p-1 text-customBlue-default font-medium"
                                        >
                                            {{
                                                i18n.disciplines_access_google_chat_space
                                            }}
                                        </button>
                                    </div>

                                    <div *ngIf="isAdmin || isTeacher">
                                        <div
                                            *appCheckPermissions="
                                                'googlechat';
                                                type: 'create'
                                            "
                                            class="flex gap-1 items-center"
                                        >
                                            <div class="p-1 w-7 h-7">
                                                <span
                                                    class="iconify w-5 h-5 text-customBlue-default"
                                                    data-icon="ic:round-sync"
                                                ></span>
                                            </div>

                                            <button
                                                type="button"
                                                (click)="
                                                    callSyncMembersGoogleSpace()
                                                "
                                                class="whitespace-nowrap p-1 text-customBlue-default font-medium"
                                            >
                                                {{
                                                    i18n.disciplines_sync_google_chat_members
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <div
                                    *ngIf="
                                        disciplineDetail.general_settings
                                            ?.google_chat
                                    "
                                >
                                    <div
                                        class="flex gap-1 items-center"
                                        *appCheckPermissions="
                                            'googlechat';
                                            type: 'create'
                                        "
                                    >
                                        <div class="p-1 w-7 h-7">
                                            <img
                                                src="./assets/svg/icon-chat.svg"
                                                [alt]="
                                                    i18n.disciplines_create_google_group_2
                                                "
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            (click)="toggleModalSend('chat')"
                                            class="whitespace-nowrap p-1 text-customBlue-default font-medium"
                                        >
                                            {{
                                                i18n.disciplines_create_google_group_2
                                            }}
                                        </button>
                                        <div class="content-tooltip">
                                            <span
                                                class="iconify-inline text-18 text-customGray-default"
                                                data-icon="carbon:help"
                                            ></span>
                                            <div
                                                class="w-80 absolute bg-customYellow-default p-2 rounded-md shadow-custom tooltip opacity-0 invisible transition-opacity duration-300 ease-in-out mt-1"
                                            >
                                                <span
                                                    class="text-center text-14 text-customGray-dark font-Roboto font-normal"
                                                >
                                                    {{
                                                        i18n.disciplines_space_tooltip_group
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </app-display-panel>
                    </button>
                </div>
            </div>
            <!--body-->
            <div *ngIf="disciplineDetail" class="flex flex-col w-full mb-6 p-2 pt-6 px-6 discipline-box">
                <ng-container
                    *ngIf="participants.length > 0"
                >
                    <div
                        *ngFor="let participant of participants"
                        class="w-full p-2 mb-4 rounded-md"
                        style="box-shadow: 0px 4px 24px 0px #00000014;"
                    >
                        <div class="flex justify-between gap-2">
                            <div 
                                class="flex items-center"
                                style="max-width: 50%;"
                            >
                                <app-user-frame
                                    [img_src]="participant?.image"
                                    [user_name]="participant?.name"
                                    [class]="'w-8 h-8 xs:w-5 xs:h-5'"
                                >
                                </app-user-frame>
                                <span
                                    class="ml-2 font-medium xs:text-12 text-customBlue-default max__width"
                                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                >
                                    {{ participant?.name }}
                                </span>
                            </div>
                            <div class="flex items-center">
                                <div
                                    class="flex items-center gap-1"
                                    *ngIf="isNotStandalone"
                                >
                                    <span
                                        class="
                                            flex items-center justify-center 
                                            font-medium xs:text-12 text-bold rounded-full 
                                            px-10 h-8 xs:px-3 xs:h-4 text-customWhite-default
                                            xs:w-24 w-40
                                        "
                                        [ngClass]="{'bg-customBlue-default': participant.isStudent, 'bg-customBlue-royal': !participant.isStudent}"
                                    >
                                        {{
                                            participant.role_name
                                                .charAt(0)
                                                .toUpperCase()
                                        }}{{ participant.role_name.slice(1) }}
                                    </span>
                                    <button
                                        *ngIf="
                                            disciplineDetail.general_settings
                                                ?.gmail
                                        "
                                        type="button"
                                        class="ml-2 p-1 xs:p-0 w-8 h-8 xs:w-5 xs:h-5"
                                        [disabled]="
                                            participant.email === user.email
                                        "
                                        [ngClass]="{
                                            'cursor-auto filter-disabled':
                                                participant.email === user.email
                                        }"
                                        (click)="openEmailBox(participant.email)"
                                    >
                                        <img
                                            src="./assets/svg/icon-mail.svg"
                                            alt="Mail"
                                        />
                                    </button>
                                    <button
                                        *ngIf="
                                            disciplineDetail.general_settings
                                                ?.google_chat
                                        "
                                        type="button"
                                        class="ml-1 p-1 xs:p-0 w-8 h-8 xs:w-5 xs:h-5"
                                        [disabled]="
                                            participant.email === user.email
                                        "
                                        [ngClass]="{
                                            'cursor-auto filter-disabled':
                                                participant.email === user.email
                                        }"
                                        (click)="openChat(participant.email)"
                                    >
                                        <img
                                            src="./assets/svg/icon-chat.svg"
                                            alt="Chat"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
                <ng-container
                    *ngIf="
                        disciplineDetail?.participants?.count?.student === 0 &&
                        disciplineDetail?.participants?.count?.teacher === 0
                    "
                >
                    <div class="flex">
                        <div class="text-customBlue-default">
                            {{ i18n.disciplines_overview_no_participants_yet }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Support Material -->
    <app-add-support-materials
        *ngIf="isNotStandalone"
        [isTeacher]="isTeacher"
        [isAdmin]="isAdmin"
    >
    </app-add-support-materials>
    <!-- Support Material -->
</ng-container>

<ng-container *ngIf="isLoading">
    <app-overview-skeleton></app-overview-skeleton>
</ng-container>

<!-- <app-loading-modal *ngIf="isLoading" [isVisible]="true"></app-loading-modal> -->

<app-modal-ementa
    [isVisibleModalEmenta]="isVisibleModalEmenta"
    [disciplineExternalID]="disciplineDetail?.external_id"
    [description]="disciplineDetail?.description"
    [i18n]="i18n"
    (close)="toggleModalEmenta()"
    (update)="receiveNewDescription($event)"
></app-modal-ementa>

<app-modal-send-message
    [isVisibleModalSend]="isVisibleModalSend"
    [userList]="students"
    [i18n]="i18n"
    [type]="modalSendType"
    [userLogged]="user"
    (close)="toggleModalSend($event)"
></app-modal-send-message>

<app-modal-create-space-google-chat
    [isVisible]="isVisibleModalCreateGoogleSpace"
    [i18n]="i18n"
    (closeModal_output)="toggleModalCreateSpaceGoogleChat()"
    (createSpace)="receiveCreateGoogleSpace($event)"
></app-modal-create-space-google-chat>
