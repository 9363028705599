import {
    Component, ElementRef, OnDestroy, OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SubmissionButtonsNavigateForumService } from './submission-buttons-navigate-forum.service';

@Component({
    selector: 'app-submission-buttons-navigate-forum',
    templateUrl: './submission-buttons-navigate-forum.component.html',
    styleUrls: ['./submission-buttons-navigate-forum.component.scss'],
})
export class SubmissionButtonsNavigateForumComponent implements OnInit, OnDestroy {
    private checkGetElementsSelectedSubscription: Subscription;
    commentsList: Element[] = [];
    currentCommentIndex = -1;
    private scrollEventListener: () => void;
    @ViewChild('panelElement', { read: ElementRef }) panelElement: ElementRef;
    constructor(private subButtonsNavigateForumService: SubmissionButtonsNavigateForumService, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.getElementsCommented();
        this.initScrollPanel();

        if (window.innerWidth < 768) {
            this.isMobile = true;
        }
    }

    getElementsCommented(): void {
        this.checkGetElementsSelectedSubscription = this.subButtonsNavigateForumService.checkGetElementsForum().subscribe({
            next: (response) => {
                if (response) {
                    this.currentCommentIndex = -1;
                    this.removeActiveComment();
                    this.commentsList = [...document.querySelectorAll('.forum__target--comment')];

                    this.commentsList.map((e) => {
                        if (!e.classList.contains('active-target')) {
                            this.currentCommentIndex = 0;
                            this.goToActiveComment();
                        }
                        return null;
                    });
                }
            }
        });
    }

    initScrollPanel(): void {
        const getList = setInterval(() => {
            const routerOutlet = document.querySelector('.router-outlet');

            if (routerOutlet) {
                this.scrollEventListener = this.renderer.listen(routerOutlet, 'scroll', this.handleScroll.bind(this));
                clearInterval(getList);
            }
        }, 400);
    }

    isMobile = false;
    handleScroll(event): void {
        this.isMobile = false;

        if (window.innerWidth < 768) {
            this.isMobile = true;
        }

        if (!this.isMobile) {
            this.panelElement.nativeElement.style.position = 'sticky';
            this.panelElement.nativeElement.style.top = '-25px';
            return;
        }

        const element = event.target;

        if (element.scrollTop > 627 && this.isMobile) {
            const topOffset = element.scrollTop - 590;

            this.panelElement.nativeElement.style.position = 'absolute';
            this.panelElement.nativeElement.style.right = '-17px';
            this.panelElement.nativeElement.style.top = `${topOffset}px`;
        }
    }

    previousComment(): void {
        if (this.currentCommentIndex > 0) {
            this.currentCommentIndex -= 1;
            this.removeActiveComment();
            this.goToActiveComment();
        }
    }

    nextComment(): void {
        if (this.currentCommentIndex < this.commentsList.length - 1) {
            this.currentCommentIndex += 1;
            this.removeActiveComment();
            this.goToActiveComment();
        }
    }

    goToActiveComment(): void {
        this.commentsList[this.currentCommentIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.commentsList[this.currentCommentIndex].classList.add('active-target');
    }

    removeActiveComment(): void {
        this.commentsList.map((e) => {
            if (e.classList.contains('active-target')) {
                e.classList.remove('active-target');
            }
            return null;
        });
    }

    ngOnDestroy() {
        if (this.checkGetElementsSelectedSubscription) {
            this.checkGetElementsSelectedSubscription.unsubscribe();
        }
    }
}
