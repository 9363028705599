<div class="container">
    <!-- FIRST CONTAINER > STATUS -->
    <app-submission-choose-submission-board
        #submissionBoard
        class="board"
        [i18n]="i18n"
        (sendIsLoadingCourseWork)="receiveIsLoadingCourseWork($event)"
    ></app-submission-choose-submission-board>

    <ng-container *ngIf="isLoadingCourseWork">
        <div 
            class="homework-wrapper"
        >
            <div
                class="homework-submission submission-box relative"
                style="height: 500px"
            >
                <div
                    style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%);
                    "
                >
                    <app-bullets-loader></app-bullets-loader>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingCourseWork">
        <div 
            class="homework-wrapper"
        >
            <!-- SECOND CONTAINER > SUBMISSIONS -->
             <div class="flex md:gap-2 relative">
                 <div 
                     class="w-full submission-box relative "
                     [ngClass]="{
                         'homework-submission ' : state?.content?.type !== 'Forum'
                     }"
                 >
                     <!-- SECOND CONTAINER > SUBMISSIONS > HEADER -->
                     <header class="header">
                         <h2 class="header__title">
                             {{ i18n.disciplines_content_submissions_activity }}
                         </h2>
     
                         <ng-container *ngIf="state?.content?.type === ''">
                             <div
                                 class="absolute right-6 text-customBlue-default border border-customGray-lighterGray px-4 py-1 rounded bg-customWhite-default"
                                 style="top: -0.375rem; z-index: 2;"
                             >
                                 <button></button>
                             </div>
                         </ng-container>
     
                         <ng-container *ngIf="state?.content?.type !== '' && course_workList.length && state?.content?.type !== 'Forum'">
                             <div
                                 class="absolute right-6 text-customBlue-default border border-customGray-lighterGray px-4 py-1 rounded bg-customWhite-default"
                                 style="top: -0.375rem; z-index: 2;"
                             >
                                 <button
                                     class="flex items-center justify-center gap-3"
                                     (click)="toggleOptions()"
                                 >
                                     <p class="font-medium">
                                         {{
                                             i18n.disciplines_content_submissions_deliveries
                                         }}
                                     </p>
                                     <i
                                         class="arrow-bottom-icon"
                                         [ngClass]="{ 'rotate--90': collapseOption }"
                                     ></i>
                                 </button>
                                 <div class="overflow-hidden">
                                     <ul [@collapse]="collapseOption">
                                         <li
                                             class="py-1 cursor-pointer text-semibold-hover"
                                             *ngFor="
                                                 let item of course_workList;
                                                 let i = index
                                             "
                                             (click)="
                                                 changeActiveCourseWork(item.try_id)
                                             "
                                             [ngClass]="{
                                                 'font-semibold':
                                                     item.try_id === tryId
                                             }"
                                         >
                                             {{
                                                 i18n.disciplines_content_submissions_deliverie
                                             }}
                                             {{ i + 1 }}
                                         </li>
                                     </ul>
                                 </div>
                             </div>
                         </ng-container>
                     </header>
     
                     <ng-container
                         *ngIf="
                             state.contentType === 'UploadQuiz' &&
                             state.activeCourseWork.contentSpecific?.results_url !==
                                 null
                         "
                     >
                         <div class="w-full flex justify-end mr-12">
                             <button
                                 (click)="sheetsAnswersUrl()"
                                 class="flex gap-2 items-center border border-customGreen-lime rounded-md px-6 py-3"
                             >
                                 <div>
                                     <span
                                         class="iconify w-6 h-6"
                                         style="color: #0f9d58"
                                         data-icon="simple-icons:googlesheets"
                                     ></span>
                                 </div>
                                 <div
                                     class="text-16 text-customGreen-lime font-medium"
                                 >
                                     {{ i18n.disciplines_view_sheet_answers }}
                                 </div>
                             </button>
                         </div>
                     </ng-container>
     
                     
                     <!-- SECOND CONTAINER > SUBMISSIONS > MAIN -->
                     <main
                         class="homework-submission__submissions"
                         [ngSwitch]="state.contentType"
                     >
                         <app-submission-type-forum
                             [i18n]="i18n"
                             [content]="content"
                             *ngSwitchCase="'Forum'"
                         ></app-submission-type-forum>
     
                         <app-submission-type-quiz
                             *ngSwitchCase="'InternalQuiz'"
                             [evaluative_content]="this.state.activeCourseWork.evaluative_content"
                             (sendCorrectionQuestion)="receiveCorrection($event)"
                         ></app-submission-type-quiz>
     
                         <app-submission-type-quiz
                             *ngSwitchCase="'UploadQuiz'"
                             [evaluative_content]="this.state.activeCourseWork.evaluative_content"
                             (sendCorrectionQuestion)="receiveCorrection($event)"
                         ></app-submission-type-quiz>
     
                         <div 
                             *ngIf="state?.contentType.includes('Quiz')"
                             class="flex justify-end self-end"
                         >
     
                             <div 
                                 *ngIf="tryIdScore !== null" 
                                 class="flex flex-col"
                             >
                                 <div  class="flex justify-end gap-2">
                                     <div  class="italic text-customBlue-default">
                                         {{ i18n.disciplines_submission_estimation }}
                                         {{
                                             tryIdScore
                                         }}
                                         {{ tryIdScore > 1 ? i18n.disciplines_content_points : i18n.disciplines_content_point  }}
                                     </div>
             
                                     <div
                                         *ngIf="tryIdScore !== null && state?.content?.evaluative_content" 
                                         class="relative cursor-pointer"
                                         (click)="displayPanelEstimation.toggle()"
                                     >
                                         <span
                                             class="iconify w-5 h-5 text-customGray-default"
                                             data-icon="f7:question-circle"
                                         ></span>
             
                                         <app-display-panel
                                             #displayPanelEstimation
                                             [style]="{ position: 'absolute' }"
                                             [custom_radius]="'10px'"
                                             [hide_arrow]="true"
                                             [panel_style]="{
                                                 background: '#FEF8E6',
                                                 width: '369px',
                                                 padding: '5px 20px'
                                             }"
                                             [newStyle]="{
                                                 right: '0',
                                                 top: '-5.5rem',
                                                 'box-shadow': '0px 4px 4px 0px #0000001A',
                                                 'border-radius': '10px'
                                             }"
                                         >
                                             <small
                                                 class="text-customGray-dark font-normal text-16"
                                             >
                                                 {{
                                                     i18n.disciplines_submission_estimation_explanation
                                                 }}
                                             </small>
                                         </app-display-panel>
                                     </div>
                                 </div>
                                 
                                 <div 
                                     class="mt-2"
                                     *ngIf="tryIdScore !== null && state?.content?.evaluative_content" 
                                 >
                                     <button
                                         type="button"
                                         class="w-full px-9 py-2 font-bold rounded bg-customWhite-default text-customBlue-default border border-customBlue-default rounded-md"
                                         (click)="correctionQuestionnaire()"
                                     >
                                         {{ i18n.disciplines_submission_review_delivery }}
                                     </button>
                                 </div>
                             </div>
                         </div>
     
                         <app-submission-type-upload
                             [i18n]="i18n"
                             *ngSwitchDefault
                         ></app-submission-type-upload>
     
                         <article
                             *ngIf="
                                 listCriterion?.length && gradesSettings.rubric_enable
                             "
                         >
                             <header class="flex items-center gap-2 mb-4">
                                 <p
                                     class="text-22 font-semibold text-customGray-dark"
                                 >
                                     {{ i18n.disciplines_content_rubric_title }}
                                 </p>
                                 <button
                                     type="button"
                                     (mouseenter)="displayPanelRubric.toggle()"
                                 >
                                     <span
                                         class="iconify-inline text-18"
                                         data-icon="carbon:help"
                                     ></span>
                                 </button>

                            <!-- Rubric sum points / total -->
                            <div class="flex justify-end ">
                                <span class="text-22 text-customGray-default font-semibold">{{ replaceDotForComma(criterionPoints) }}/{{ replaceDotForComma(returnValuesPoints()) }}</span> 
                            </div>
                             </header>
                             <!-- Tooltip -->
                             <div class="relative">
                                 <app-display-panel
                                     #displayPanelRubric
                                     [style]="{ position: 'absolute' }"
                                     [custom_radius]="'10px'"
                                     [hide_arrow]="true"
                                     [panel_style]="{
                                         background: '#FEF8E6',
                                         width: '15.5rem'
                                     }"
                                     [newStyle]="{ top: '-12.25rem' }"
                                 >
                                     <small>
                                         {{
                                             i18n.disciplines_content_card_rubric_tooltip
                                         }}
                                     </small>
                                 </app-display-panel>
                             </div>
                             <ul class="flex flex-col gap-7">
                                 <li *ngFor="let criterion of listCriterion">
                                     <app-card-rubric
                                         [receiveCriterion]="criterion"
                                         (sendIDLevel)="receiveRubricLevels($event)"
                                     ></app-card-rubric>
                                 </li>
                             </ul>
                         </article>
                    <!-- Rubric sum points / total -->
                    <ng-container *ngIf="
                        listCriterion?.length && gradesSettings.rubric_enable
                    ">
                        <div class="border-t border-customGray-lighterGray w-full left-0 pt-7 px-7">
                            <div class="flex justify-end ">
                                <div class="flex bg-customGray-lighterGray p-1 rounded-md text-customBlue-default text-16 gap-4">
                                    <span class="font-normal">
                                    {{ i18n.grades_sum_rubrics }}
                                    </span>
                                    <span class="font-medium">{{ replaceDotForComma(criterionPoints) }}/{{ replaceDotForComma(returnValuesPoints()) }}</span> 
                                </div>
                            </div>
                        </div>
                    </ng-container>
                     </main>
                 </div>
                 <!-- Buttons to navigate when the content is Forum -->
                 <app-submission-buttons-navigate-forum
                     *ngIf="state.contentType.includes('Forum')"
                 ></app-submission-buttons-navigate-forum>
             </div>
            <form
                *ngIf="state?.content?.evaluative_content"
                [formGroup]="gradeForm"
                class="flex flex-col md:flex-row gap-3 justify-between items-center bg-customWhite-default p-6 rounded-xl homework-submission_box--shadow"
            >
                <div class="flex flex-col gap-2">
                    <div
                        class="text-18 text-customBlue-default font-medium mb-2"
                    >
                        {{ gradesSettings.concept_enable ? i18n.disciplines_content_concept : i18n.disciplines_content_summary_activity }}
                    </div>
                    <div *ngIf="false">
                        <div class="flex gap-4 mb-2">
                            <div class="flex gap-2">
                                <div>
                                    <i
                                        class="icon circle-x-fill-icon w-5 h-5"
                                    ></i>
                                </div>

                                <div class="text-16 text-customOrange-default">
                                    {{ resume?.wrong }}
                                    {{ i18n.disciplines_quiz_wrongs }}
                                </div>
                            </div>
                            <div class="flex gap-2">
                                <div>
                                    <i class="icon check-fill-icon w-5 h-5"></i>
                                </div>

                                <div class="text-16 text-customGreen-approved">
                                    {{ resume?.right }}
                                    {{ i18n.disciplines_quiz_rights }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex items-center justify-between gap-2 text-customGray-dark"
                        *ngIf="gradesSettings.concept_enable"
                    >
                        <select
                            name="concept_id"
                            id="concept_id"
                            class="p-2 border border-customGray-lighterGray text-customGray-dark"
                            [(ngModel)]="concept_id"
                        >
                            <option
                                [value]="concepet.id"
                                *ngFor="let concepet of conceptsList"
                            >
                                {{ concepet.name }} ({{
                                    i18n.disciplines_content_of
                                }}
                                {{ concepet.range_start }} -
                                {{ concepet.range_end }})
                            </option>
                        </select>
                    </div>
                    <div class="relative flex items-center gap-4 mt-2">
                        <span class="text-customGray-dark">
                            <span *ngIf="gradesSettings.concept_enable"
                                >{{
                                    i18n.disciplines_content_grade_provided
                                }}:</span
                            >
                            <span *ngIf="!gradesSettings.concept_enable"
                                >{{
                                    i18n.disciplines_content_submissions_final_score
                                }}:</span
                            >
                        </span>
                        <div class="flex flex-col">
                            <input
                                #gradeValue
                                (input)="onInputChange(gradeValue)"
                                type="text"
                                class="p-2 border border-customGray-lighterGray text-customGray-dark"
                                [ngClass]="{
                                    'border-inicieColors-orange': gradeForm.invalid
                                }"
                                (focus)="fnClearIfZeroGrade($event.target)"
                                [appDecimalPlaces]="decimal_places_mask"
                                (onValueChange)="receiveFromDecimalPlaces($event)"
                                required
                                [readonly]="!can_edit_grade"
                                formControlName="gradeValue"
                                (keyup)="validateMaxValue()"
                                (click)="fnDisplayPanelApplyGrade()"
                            />
                            <app-display-panel
                                #displayPanelApplyGrade
                                [style]="{ position: 'absolute' }"
                                [custom_radius]="'10px'"
                                [hide_arrow]="true"
                                [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
                                [newStyle]="{ top: '-4rem', right: '-4rem' }"
                            >
                                <small>
                                    {{ i18n.grades_only_apply_grade_by_rubric }}
                                </small>
                            </app-display-panel>
                            <div *ngIf="maximum_grade !== null">
                                <p
                                    class="text-sm"
                                    style="color: #e8532c"
                                    *ngIf="!controlButtonForm"
                                >
                                    {{ i18n.grades_maximum_score_activity }}:
                                    {{ maximum_grade }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col self-end gap-2">
                    <div class="flex gap-2 items-center">

                    </div>

                    <div class="flex justify-start">
                        <button
                            type="button"
                            class="w-full px-9 py-2 rounded bg-customBlue-default text-customWhite-default font-bold"
                            (click)="postGradeCourseWork()"
                            [ngClass]="{'disabled:opacity-20 cursor-not-allowed':isBtnDisabled()}" 
                            [disabled]="isBtnDisabled()" 
                        >
                            {{ i18n.disciplines_submission_save_grade_coursework }}
                        </button>
                    </div>
                </div>
            </form>
            <!-- THIRD CONTAINER > RESUME -->
            <app-submission-approve-final-score
                *ngIf="
                    state?.contentType !== 'UploadQuiz' &&
                    state?.contentType !== 'InternalQuiz' &&
                    state?.contentType !== 'Forum' &&
                    state?.contentType !== 'LTI' &&
                    enableDownload
                "
                class="approve-final-score"
                [type]="state.contentType"
                [files]="file"
                [i18n]="i18n"
            ></app-submission-approve-final-score>
        </div>
    </ng-container>

    
</div>

<app-decision-modal></app-decision-modal>
<app-message-modal> </app-message-modal>
<app-loading-modal [isVisible]="isLoading"></app-loading-modal>
