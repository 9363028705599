<div class="relative bg-customWhite-default rounded-3xl p-2 text-customGray-dark">
    <app-forum-detail
        [isComponentForum]="true"
        [forum_post]="content.contentSpecific"
    ></app-forum-detail>

    <app-forum-comment
        [isComponentForum]="true"
        [hiddenSocialEvents]="true"
        [isComponentFromSubmission]="true"
    ></app-forum-comment>
</div>