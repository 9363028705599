import {
    Component, Input, OnDestroy, OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { HomeworkSubmissionsState, selectHomeworkSubmissionsState } from '../../../../store/homework-submissions.reducer';
import { SubmissionButtonsNavigateForumService } from '../submission-buttons-navigate-forum/submission-buttons-navigate-forum.service';

@Component({
    selector: 'app-submission-type-forum',
    templateUrl: './submission-type-forum.component.html',
    styleUrls: ['./submission-type-forum.component.scss']
})
export class SubmissionTypeForumComponent implements OnInit, OnDestroy {
    @Input() i18n: any = {};
    @Input() content: any = {};

    state$: Subscription;
    state: HomeworkSubmissionsState;

    constructor(private store: Store, private subButtonsNavigateForumService: SubmissionButtonsNavigateForumService) {}

    ngOnInit(): void {
        this.state$ = this.store.select(selectHomeworkSubmissionsState).subscribe((state) => {
            if (state) {
                this.state = state;
                if (this.state.activeCourseWork?.user?.external_id) {
                    /*
                    Gets the externalID of the selected user
                    For to compare with the author's externalID in Forum
                    If the same, the user will soon be able to navigate between their forums
                    */
                    this.subButtonsNavigateForumService.updateSelectedUserExternalID(this.state.activeCourseWork.user.external_id);

                    /*
                    Updates the state of the element
                    To search the forums again new user
                    */
                    setTimeout(() => {
                        this.subButtonsNavigateForumService.updateStateElementsForum(true);
                    }, 500);
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.state$) this.state$.unsubscribe();
    }
}
