<!-- Reply of replies -->
<ng-container *ngIf="!renderFromTemplate">
    <div class="flex mt-8 mb-4 gap-2 cursor-pointer" routerLink="../..">
        <i class="comment-outlined-icon comment-icon"></i>
        <div  class="italic">{{i18n.forum_back_to_replies}}</div>
    </div>
</ng-container>
<!-- End Reply of replies -->

<div 
    id="forum_post" 
    #forum_post_anchor 
    class=""
    [ngClass]="{'pb-8': forum_post?.replies?.length && forum_post?.isThreadOpen}"
>
    <!-- Card reply -->
    <app-forum-card-reply
        [isThreadReply]="false"
        [thread]="forum_post"
        [i18n]="i18n"
        [user]="user"
        [isCommentAreaVisible]="isCommentAreaVisible"
        [isEditMode]="isEditMode"
        [isCanForumPost]="isCanForumPost"
        [isLoading]="isLoading"
        [forum_post]="forum_post"
        [isReplyOpen]="isReplyOpen"
        [hiddenSocialEvents]="hiddenSocialEvents"
        (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
        (sendIsEditMode)="receiveIsEditMode($event)"
        (sendIsReplyOpen)="receiveIsReplyOpen($event)"
        (askForGetForum)="askForGetForum()"
        (sendEditHistoryObject)="receiveEditHistoryObject($event)"
    ></app-forum-card-reply>

    <!-- AREA DE COMENTARIO DE UM THREAD -->
    <ng-container *ngIf="isCommentAreaVisible === forum_post?.id">
        <app-forum-area-text
            [thread]="forum_post"
            [isEditMode]="isEditMode"
            [i18n]="i18n"
            [user]="user"
            [isReplyOpen]="isReplyOpen"
            (sendIsEditMode)="receiveIsEditMode($event)"
            (sendIsReplyOpen)="receiveIsReplyOpen($event)"
            (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
            (sendSaveComment)="saveComment($event)"
        ></app-forum-area-text>
    </ng-container>
    <!-- FIM AREA DE COMENTARIO DE UM THREAD -->

    <!-- AREA DE REPLIES -->
    <div [@collapse]="forum_post?.isThreadOpen">
        <ng-container *ngFor="let thread of forum_post?.replies; let i = index">
            <app-forum-replies
                [isThreadReply]="true"
                [thread]="thread"
                [i18n]="i18n"
                [user]="user"
                [isCommentAreaVisible]="isCommentAreaVisible"
                [isEditMode]="isEditMode"
                [isCanForumPost]="isCanForumPost"
                [isLoading]="isLoading"
                [forum_post]="forum_post"
                [isReplyOpen]="isReplyOpen"
                [index]="i"
                [isLastLevel]="countReplies(forum_post) === i"
                [hiddenSocialEvents]="hiddenSocialEvents"
                (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
                (sendIsEditMode)="receiveIsEditMode($event)"
                (sendIsReplyOpen)="receiveIsReplyOpen($event)"
                (askForGetForum)="askForGetForum()"
                (sendEditHistoryObject)="receiveEditHistoryObject($event)"
                (sendSaveComment)="saveComment($event)"
            ></app-forum-replies>
        </ng-container>
    </div>
    <!-- FIM AREA DE REPLYS -->
</div>
<hr 
    style="color: #C7CBD1"
    class="my-6"
>

<!-- AREA DE NOVO COMENTÁRIO -->
<ng-container *ngIf="isCommentAreaVisible === '' && !renderFromTemplate && showCommentArea">
    <app-forum-area-text
        [thread]="forum_post"
        [isEditMode]="isEditMode"
        [i18n]="i18n"
        [user]="user"
        (sendIsEditMode)="receiveIsEditMode($event)"
        (sendIsReplyOpen)="receiveIsReplyOpen($event)"
        (sendIsCommentAreaVisible)="receiveIsCommentAreaVisible($event)"
        (sendSaveComment)="saveComment($event)"
    ></app-forum-area-text>
</ng-container>
<!-- FIM AREA DE NOVO COMENTÁRIO -->

<app-modal-edit-history
    [isModalOpen]="isModalEditHistoryOpen"
    [edit_history]="edit_history"
    (sendIsModalOpen)="closeModalHistory($event)">
</app-modal-edit-history>
