import {
    AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild
} from '@angular/core';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';
import { SharedService } from 'src/app/shared/shared.service';

import { SubmissionCommentComponent } from '../submission-comment/submission-comment.component';

@Component({
    selector: 'app-submission-overview-box',
    templateUrl: './submission-overview-box.component.html',
    styleUrls: ['./submission-overview-box.component.scss']
})

export class SubmissionOverviewBoxComponent implements OnInit, AfterViewInit {
    i18n: any = {};
    isCommentOpen = false;
    @ViewChild('title') title: ElementRef;
    @ViewChild('scrollToElement') scrollToElement: ElementRef;
    @ViewChild('refSubmissionComment') refSubmissionComment: SubmissionCommentComponent;
    @Input() question_number?: number;
    @Input() question_subject?: string;
    @Input() question_title?: string;
    @Input() question_title_left_padding?: string = '0px';
    @Input() score_value?: number;
    @Input() comments?: any = [];
    @Input() hide_question_number? = false;

    constructor(
        public shared: SharedService,
        private restoreFormattingService: RestoreFormattingService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
    }

    ngAfterViewInit(): void {
        if (this.question_title) {
            this.restoreFormattingService.restoreFormatting(this.title);
        }
    }

    getTranslations() {
        this.i18n = this.shared.getTranslationsOf('Disciplines');
    }

    scrollTo() {
        this.refSubmissionComment.commentsVisibility = !this.refSubmissionComment.commentsVisibility;
        if (this.refSubmissionComment.commentsVisibility) {
            const element = this.scrollToElement.nativeElement;
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
