import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { WindowRef } from 'src/app/shared/window-ref';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// fontawsome
// import { ProgressBarModule } from 'angular-progress-bar';
import { AuthGuard } from './auth/auth-guard.service';
import { ButtonsModule } from './components/buttons/buttons.module';
import { CountdownComponentModule } from './components/countdown/countdown.module';
import { DisplayPanelModule } from './components/display-panel/display-panel.module';
import { DropdownsModule } from './components/dropdowns/dropdowns.module';
import { EffectsModule } from './components/effects/effects.module';
import { HeaderModule } from './components/header/header.module';
import { ImpersonificationModule } from './components/impersonfication/impersonification.module';
import { MessengerToastModule } from './components/messenger-toast/messenger-toast.module';
import { ModalConfirmationModule } from './components/modal-confirmation/modal-confirmation.module';
import { CookiesEnabledModalComponent } from './components/modals/cookies-enabled-modal/cookies-enabled-modal.component';
import { DecisionModalModule } from './components/modals/decision-modal/decision-modal.module';
import { FormModalModule } from './components/modals/form-modal/form-modal.module';
import { GenericModalModule } from './components/modals/generic-modal/generic-modal.module';
import { LoadingModalModule } from './components/modals/loading-modal/loading-modal.module';
import { MessageModalModule } from './components/modals/message-modal/message-modal.module';
import { PaginationModule } from './components/pagination/pagination.module';
import { ResetModule } from './components/reset/reset.module';
import { SearchInputModule } from './components/search/search.module';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { ThirdPartyCookieModule } from './components/third-party-cookie/third-party-cookie.module';
import { UploadModule } from './components/upload/upload.module';
import { UploadFileListModule } from './components/upload-file-list/upload-file-list.module';
import { UploadFilePreviewModule } from './components/upload-file-preview/upload-file-preview.module';
import { UploadInlineModule } from './components/upload-inline/upload-inline.module';
import { UserWayModule } from './components/user-way/user-way.module';
import { VlibrasModule } from './components/vlibras/vlibras.module';
import { PermissionModule } from './directives/permission/permission.module';
import { CertificatesValidatorModule } from './pages/certificates-validator/certificates-validator.module';
import { ContentPlannerModule } from './pages/content-planner/content-planner.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { QuestionBankModule } from './pages/disciplines/components/question-bank/question-bank.module';
import { DisciplinesModelsModule } from './pages/disciplines-models/disciplines-models.module';
import { HomeModule } from './pages/home/home.module';
import { ImportModule } from './pages/import/import.module';
import { ImportSpreadsheetModule } from './pages/import-spreadsheet/import-spreadsheet.module';
// Modules
import { LoginModule } from './pages/login/login.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OrgUnitsModule } from './pages/org-units/org-units.module';
import { PermissionsModule } from './pages/permissions/permissions.module';
import { ProfileModule } from './pages/profile/profile.module';
import { ResetPasswordModule } from './pages/reset-password/reset-password.module';
import { SportsModule } from './pages/sports/sports.module';
import { UsersModule } from './pages/users/users.module';
import { metaReducers, reducers } from './reducers';
import { ErrorReportingService } from './services/errorReproting/error-reporting.service';
// import { GoogleInitOptions, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { TokenInterceptor } from './services/http.interceptor';
import { RestoreFormattingService } from './shared/restore-formatting.service';
import { SharedService } from './shared/shared.service';

registerLocaleData(localePt);
registerLocaleData(localeEs);
registerLocaleData(localeEn);

@NgModule({
    declarations: [AppComponent, NotFoundComponent, CookiesEnabledModalComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DisciplinesModelsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            timeOut: 3000,
            preventDuplicates: true,
        }), // ToastrModule added
        // Modulos Nativos
        // SettingsModule,
        LoginModule,
        DashboardModule,
        // DisciplinesModule,
        OrgUnitsModule,
        PermissionsModule,
        UsersModule,
        ResetModule,
        ProfileModule,
        ImportModule,
        ImportSpreadsheetModule,
        UploadModule,
        UploadInlineModule,
        ModalConfirmationModule,
        HeaderModule,
        SidebarModule,
        HomeModule,
        GenericModalModule,
        CountdownComponentModule,
        CertificatesValidatorModule,
        // ProgressBarModule,
        LoadingModalModule,
        DecisionModalModule,
        MessageModalModule,
        DisplayPanelModule,
        ImpersonificationModule,
        FormModalModule,
        DropdownsModule,
        ButtonsModule,
        PermissionModule,
        EffectsModule,
        PaginationModule,
        QuestionBankModule,
        ResetPasswordModule,
        VlibrasModule,
        UserWayModule,
        ContentPlannerModule,
        SportsModule,
        // -- Fim Modulos Nativos
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        StoreDevtoolsModule.instrument(),
        MessengerToastModule,
        SearchInputModule,
        ThirdPartyCookieModule,
        UploadFileListModule,
        UploadFilePreviewModule,
    ],
    providers: [
        AuthGuard,
        SharedService,
        RestoreFormattingService,
        WindowRef,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR',
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: Window, useValue: window },
        { provide: ErrorHandler, useClass: ErrorReportingService }

    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
