import {
    animate,
    AUTO_STYLE,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-forum-replies',
    templateUrl: './forum-replies.component.html',
    styleUrls: ['./forum-replies.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0px', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class ForumRepliesComponent {
    @Input() thread: any;
    @Input() i18n: any;
    @Input() user: any;
    @Input() isThreadReply: boolean;
    @Input() isCommentAreaVisible: string;
    @Input() isEditMode: boolean;
    @Input() isCanForumPost: boolean;
    @Input() isLoading: boolean;
    @Input() commentForumForm: UntypedFormGroup;
    @Input() forum_post: any;
    @Input() isReplyOpen: string;
    @Input() index: number;
    @Input() isLastLevel: boolean;
    @Input() hiddenSocialEvents = false;

    @Output() sendEditHistoryObject = new EventEmitter();
    @Output() askForGetForum = new EventEmitter();
    @Output() sendCancelReply = new EventEmitter();
    @Output() sendIsCommentAreaVisible = new EventEmitter<string>();
    @Output() sendIsReplyOpen = new EventEmitter<string>();
    @Output() sendIsEditMode = new EventEmitter<boolean>();
    @Output() sendSaveComment = new EventEmitter();

    fnAskForGetForum() {
        this.askForGetForum.emit();
    }

    fnReceiveEditHistoryObject(thread_edit_history: any) {
        this.sendEditHistoryObject.emit(thread_edit_history);
    }
    receiveIsEditMode(isEditMode) {
        this.sendIsEditMode.emit(isEditMode);
    }

    receiveIsCommentAreaVisible(isCommentAreaVisible: string) {
        this.sendIsCommentAreaVisible.emit(isCommentAreaVisible);
    }

    receiveIsReplyOpen(isReplyOpen: string) {
        this.sendIsReplyOpen.emit(isReplyOpen);
    }

    saveComment(commentObject: any) {
        this.sendSaveComment.emit(commentObject);
    }
}
