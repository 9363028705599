<ng-container *ngIf="(loading$ | async) === false">
  <div class="submission__info">
    <div class="go-back-button" (click)="goBackPreviousPage()">
      <i class="iconify-inline" data-icon="ep:arrow-left-bold"></i>
      <div class="go-back-button__text">{{ goBackToGrade ? i18n.disciplines_content_submissions_btn_back_grade : i18n.disciplines_content_submissions_btn_back_content }}</div>
    </div>

    <div class="info__type info">
      <!-- <i class="sketchbook-outline-icon info__icon"></i> -->
      <div>
        <app-content-types-icons
            [size]="{'width': '16px', 'height': '16px'}" 
            [type]="submissionData.content?.type"
        ></app-content-types-icons>
      </div>
      {{ i18n.disciplines_content_submissions_type }}: {{ getTraductionTypeContent(submissionData.content?.type) }}
    </div>

    <div class="info__deadline info" *ngIf="submissionData.content.end_date">
      <i class="calendar-outline-icon info__icon"></i>
      {{ i18n.disciplines_content_submissions_date_limit }}:
        <ng-container *ngIf="shared.getSelectedLanguage() !== 'en'">
            {{ submissionData?.content?.end_date | date: 'dd/MM/yyyy'}} {{i18n.upload_hours}} {{ submissionData?.content?.end_date | date: 'HH:mm' | replace: ':' : 'h' }}
        </ng-container>
        <ng-container *ngIf="shared.getSelectedLanguage() === 'en'">
            {{ submissionData?.content?.end_date | date: 'MM/dd/yyyy'}} {{i18n.upload_hours}} {{ submissionData?.content?.end_date | date: 'h:mm a' }}
        </ng-container>
    </div>
  </div>

  <div class="select-view">
    <div *ngIf="submissionData" class="select-view__info">
      <app-content-types-icons [type]="submissionData.content.type"></app-content-types-icons>
      {{ submissionData.content.name }}
    </div>

    <div class="select-view__tabs">
      <a (click)="changeTab('overview')" class="select-view__tab over" [class.active]="activeTab === 'overview'">{{ i18n.disciplines_select_tab_overview }}</a>
      <a (click)="changeTab('resume')" class="select-view__tab res" [class.active]="activeTab === 'resume'">{{ i18n.disciplines_content_submissions_summary_finish }}</a>
      <a (click)="changeTab('submissions')" class="select-view__tab sub" [class.active]="activeTab === 'submissions'">{{ i18n.disciplines_content_submissions_deliveries }}</a>
    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="loading$ | async">
    <div class="relative"
        style="height: 500px;"
    >   
        <div 
            style="position: absolute; top: 50%; left: 50%; transform: translateX(-50%);"
        > 
            <app-bullets-loader></app-bullets-loader>
        </div>
    </div>
</ng-container>
