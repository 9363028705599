import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    BehaviorSubject, Observable, Subject, take
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ForumService {
    forum:Subject<any> = new BehaviorSubject<any>([]);
    reply:Subject<any> = new BehaviorSubject<any>([]);
    isForumUpdated$:Subject<boolean> = new BehaviorSubject<boolean>(false);
    isForumDeleted$:Subject<any> = new BehaviorSubject<any>([]);
    isForumSaved$:Subject<any> = new BehaviorSubject<any>([]);
    gradeValueChanged$:Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient
    ) { }

    // Observable Forum
    get forum$() {
        return this.forum.asObservable()
            .pipe(
                filter((forum:any) => !!forum)
            );
    }

    // Observable Reply
    get reply$() {
        return this.reply.asObservable()
            .pipe(
                filter((reply:any) => !!reply)
            );
    }

    addForumToObservable(data:any) {
        this.forum.next(data);
    }

    addRepliesToObservable(data:any) {
        this.reply.next(data);
    }

    checkForUpdatesOnForum() {
        this.isForumUpdated$.next(true);
    }

    checkForDeleteOnForum(id?:any, delete_canceled?: boolean) {
        if (delete_canceled) {
            this.isForumDeleted$.next([]);
        } else {
            this.isForumDeleted$.next(id);
        }
    }

    getForum(type:any, external_id: any, page: number, per_page: number, query: any, filter: any, order: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/v2/forums/${type}/${external_id}?page=${page}&search=${query}&filter=${filter}&per_page=${per_page}&order=${order}`);
    }

    getForumPost(external_id: any, page: number, per_page: number, order: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/v2/forum_post/${external_id}?page=${page}&per_page=${per_page}&order=${order}`).pipe(take(1));
    }

    setGrade(forum_post_id:any, params): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum_post/${forum_post_id}/grade`, params);
    }

    postForum(forum_external_id:any, params): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum/${forum_external_id}/forum_post`, params);
    }

    patchForum(forum_post_id:any, params): Observable<any> {
        return this.http.patch<any>(`${API_Routes.URL}/v2/forum_post/${forum_post_id}`, params);
    }

    postLikeForumPost(forum_post_id:any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum_post/${forum_post_id}/like`, '');
    }

    postUnlikeForumPost(forum_post_id:any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum_post/${forum_post_id}/unlike`, '');
    }

    postFollowForumPost(forum_post_id:any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum_post/${forum_post_id}/follow`, '');
    }

    postUnfollowForumPost(forum_post_id:any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum_post/${forum_post_id}/unfollow`, '');
    }

    postViewForum(params: any): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/v2/forum_post/`, params);
    }

    deleteForum(forum_id:any): Observable<any> {
        return this.http.delete<any>(`${API_Routes.URL}/v2/forum_post/${forum_id}`);
    }

    getEditHistory(forum_post_id: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/forum_post/${forum_post_id}/history`);
    }
}
