import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ActivatedRoute, Params, Router, UrlSegment
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ContentService } from '../../content.service';
import {
    setActiveTab,
    setLoading,
    setOverallData,
    updateSubmissions,
} from './store/homework-submissions.actions';
import {
    selectActiveTab,
    selectLoading,
} from './store/homework-submissions.reducer';

@Component({
    selector: 'app-homework-submissions',
    templateUrl: './homework-submissions.component.html',
    styleUrls: ['./homework-submissions.component.scss'],
})
export class HomeworkSubmissionsComponent implements OnInit, OnDestroy {
    homeworkSubmission$: Subscription;
    submissionData: any = {
        content: {},
    };
    submissions;
    deadline;
    contentID: string;
    disciplineID: string;
    loading$ = this.store.select(selectLoading);
    activeTab$: Subscription;
    activeTab;
    i18n: any = {};
    courseWorkId = '';
    params: any = {};
    goBackToGrade = false;

    constructor(
        private submissionsService: SubmissionsService,
        private router: Router,
        private route: ActivatedRoute,
        public shared: SharedService,
        private store: Store,
        private platformModalsService: PlatformModalsService,
        private contentService: ContentService
    ) {}
    // ngOnDestroy(): void {
    //     throw new Error('Method not implemented.');
    // }

    ngOnInit(): void {
        this.setLoading(true);
        this.contentService.receiveNewStateSubmissions = false;
        this.getTranslations();
        this.extractContentID();
        this.extractDisciplineID();
        this.extractCourseWorkId();
        this.getHomeworkSubmissionsData();
        this.boostrapActiveTab();
        this.routeChangeListener();

        this.activeTab$ = this.store.select(selectActiveTab).subscribe((tab) => {
            if (this.activeTab === tab) return;
            this.activeTab = tab;
        });
    }

    changeTab(tab: string) {
        this.store.dispatch(setActiveTab({ activeTab: tab }));
        this.router.navigateByUrl(
            `disciplines/${this.disciplineID}/${this.route.snapshot.url[0].path}/homework-submissions/${this.contentID}/${tab}`
        );
    }

    /* PARAMS AND DATA */
    extractContentID() {
        const filteredUrl: any = this.router.url;
        const regex = /homework-submissions\/(.*)\//;

        this.contentID = filteredUrl.match(regex)[1];
    }

    extractDisciplineID() {
        const filteredUrl: any = this.router.url;
        const regex = /disciplines\/(.+?)\//;

        this.disciplineID = filteredUrl.match(regex)[1];
    }

    extractCourseWorkId(): void {
        this.route?.firstChild?.params.subscribe((params: Params) => {
            if (params.hasOwnProperty('coursework')) {
                this.courseWorkId = params.coursework;
            }

            this.params = params;

            if (this.params?.period_id && this.params?.user_external_id) {
                this.goBackToGrade = true;
            }
        });
    }

    errorMessage(statusCode: any, errorMessage: any) {
        if (Object.prototype.hasOwnProperty.call(this.i18n, errorMessage)) {
            return this.i18n[errorMessage];
        }
        return this.i18n.error_system_error;
    }

    getHomeworkSubmissionsData() {
        this.homeworkSubmission$ = this.submissionsService
            .getHomeworkSubmissions(this.contentID)
            .subscribe({
                next: (state: any) => {
                    if (!state.content.name) return;

                    if (!state.submissions.length && state.last_submissions.length) {
                        state.last_submissions.forEach((last_submissions) => {
                            last_submissions.course_work = [last_submissions.course_work];
                            state.submissions.push(last_submissions);
                        });
                    }

                    this.submissionData = state;
                    this.deadline = state.content.end_date;
                    this.submissions = state.submissions;
                    const contentType = state.content.type;
                    const contentDescription = state.content.description;
                    const contentName = state.content.name;
                    const { summary } = state;

                    this.store.dispatch(
                        setOverallData({
                            content: state.content,
                            contentID: this.contentID,
                            deadline: this.deadline,
                            submissions: this.submissions,
                            concludedSubmissions: this.submissions,
                            contentName,
                            contentDescription,
                            contentType,
                            summary,
                        })
                    );

                    if (this.submissions[0]?.status?.translation) return this.setLoading(false);
                    this.sortSubmissionsByDeadline();
                    this.insertSubmissionStatusTranslations();

                    const concludedSubmissions = this.submissions;

                    // if (!concludedSubmissions[0]?.course_work?.at(-1).id) return;

                    if (!this.courseWorkId.length) {
                        this.courseWorkId = concludedSubmissions.filter((concludedSubmission) => concludedSubmission.course_work?.at(-1).completed_at !== null)[0].course_work?.at(-1).id;
                        console.log('this.courseWorkId ', this.courseWorkId);
                    }

                    this.submissionsService
                        .getCourseWork(this.contentID, this.courseWorkId)
                        .subscribe({
                            next: (courseWork: any) => {
                                this.store.dispatch(
                                    updateSubmissions({
                                        submissions: this.submissions,
                                        concludedSubmissions,
                                        activeCourseWork: courseWork,
                                    })
                                );

                                this.setLoading(false);
                                this.contentService.receiveNewStateSubmissions = true;
                            },
                            error: (err) => {
                                this.platformModalsService.toggle('message', err.error.error, 'close');
                            }
                        });
                },
                error: (err) => {
                    const message = this.errorMessage(err.status, err.error.error);
                    this.platformModalsService.toggle('message', message, 'close');
                }
            });
    }

    sortSubmissionsByDeadline() {
        this.submissions = [...this.submissions];
        this.submissions.sort((a, b) => {
            a = a.course_work.completed_at;
            b = b.course_work.completed_at;

            const aDate = +new Date(a);
            const bDate = +new Date(b);

            return bDate - aDate;
        });
    }

    insertSubmissionStatusTranslations() {
        this.submissions = this.submissions.map((sub) => {
            const { status } = sub;
            const statusName = sub.status.name;

            switch (statusName) {
                case 'pending':
                    return { ...sub, status: { ...status, translation: 'Pendente' } };

                case 'late':
                    return { ...sub, status: { ...status, translation: 'Atrasada' } };

                default:
                    return { ...sub, status: { ...status, translation: 'Concluída' } };
            }
        });
    }

    getConcludedSubmissions() {
        return this.submissions.filter((sub) => sub.course_work.at(-1).completed_at !== null);
    }

    goBackPreviousPage(): void {
        if (this.params?.period_id && this.params?.user_external_id) {
            this.router.navigateByUrl(`disciplines/${this.disciplineID}/grades/period/${this.params.period_id}/student/${this.params.user_external_id}`);
        } else {
            this.router.navigateByUrl(`disciplines/${this.disciplineID}/content-tab`);
        }
    }

    /* TOGGLE ACTIVE TAB */
    boostrapActiveTab() {
    // const filteredUrl: any = this.router.url;
    // const regex = /homework-submissions\/.*\/(.*)/;
        let tab;

        this.route?.firstChild?.url.subscribe((url: UrlSegment[]) => {
            url.forEach((element) => {
                tab = element?.path;
            });
        });

        if (!tab) return;

        this.store.dispatch(setActiveTab({ activeTab: tab }));
    }

    routeChangeListener() {
        this.shared.CallFunctionOnRouteChange(this.boostrapActiveTab.bind(this));
    }

    setLoading(boolean: boolean) {
        this.store.dispatch(setLoading({ loading: boolean }));
    }

    getTranslations() {
        this.i18n = {
            ...this.shared.getTranslationsOf('Disciplines'),
            ...this.shared.getTranslationsOf('Errors'),
            ...this.shared.getTranslationsOf('Upload'),
        };
    }

    getTraductionTypeContent(type: string): string | undefined {
        if (type === null || type === undefined) {
            return '';
        }

        // Assignment
        if (type === 'Assignment' || type === 'UploadAssignment') {
            return this.i18n.disciplines_type_upload_assignment;
        }

        // DocsAssignment
        if (type === 'DocsAssignment') {
            return this.i18n.disciplines_type_docs_assignment;
        }

        // SheetsAssignment
        if (type === 'SheetsAssignment') {
            return this.i18n.disciplines_type_sheets_assignment;
        }

        // File
        if (type === 'File') {
            return this.i18n.disciplines_page_file;
        }

        // Youtube
        if (type === 'Youtube') {
            return this.i18n.disciplines_youtube;
        }

        // TextPage
        if (type === 'TextPage') {
            return this.i18n.disciplines_text_page;
        }

        // Link
        if (type === 'Link') {
            return this.i18n.disciplines_url;
        }

        // Forum
        if (type === 'Forum') {
            return this.i18n.disciplines_select_tab_forum;
        }

        // Conference
        if (type === 'Conference' || type === 'InternalConference') {
            return this.i18n.disciplines_conference;
        }

        // Quiz
        if (type === 'InternalQuiz') {
            return this.i18n.disciplines_upload_quiz;
        }

        // Quiz
        if (type === 'UploadQuiz') {
            return this.i18n.disciplines_upload_quiz_external;
        }

        // Scorm
        if (type === 'Scorm') {
            return this.i18n.disciplines_scorm;
        }

        // LTI
        if (type === 'LTI') {
            return this.i18n.disciplines_page_lti;
        }
    }

    ngOnDestroy() {
        this.shared.CallFunctionOnRouteChange();
        if (this.activeTab$) this.activeTab$.unsubscribe();
    }
}
