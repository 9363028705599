import {
    AfterViewInit, Component, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ForumService } from '../../forum.service';
import { ForumSharedService } from '../../shared/forum-shared.service';

@Component({
    selector: 'app-forum-list',
    templateUrl: './forum-list.component.html',
    styleUrls: ['./forum-list.component.scss'],
})
export class ForumListComponent implements OnInit, AfterViewInit {
    // ---- Pagination ----
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;
    paginationParams: any = {};
    activePage = 1;
    per_page = 10;

    showModal = false;
    isLoading = false;
    isModalOpen = false;

    discipline_external_id: any;
    forums: any;
    hasntForums = false;
    hasntResults = false;

    filterOpen = false;
    isEditMode = false;
    forum_post_id: any;
    threadText = '';

    user: any;
    i18n: any = [];

    query = '';
    filter = '';
    order = 'DESC';
    titlePage = '';
    placeholderSearch = '';

    filterTerm: string;

    constructor(
        private route: ActivatedRoute,
        private sharedService: SharedService,
        private forumService: ForumService,
        private router: Router,
        public platModalService: PlatformModalsService,
        public forumSharedService: ForumSharedService
    ) {}

    ngOnInit(): void {
        this.getTranslations();
        this.getUser();
        this.getDisciplineId();
        this.getForum();
    }

    ngAfterViewInit(): void {
        this.forumService.isForumDeleted$.subscribe((changes) => {
            if (changes?.length) {
                this.deleteForum(changes);
            }
        });
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Forum'), ...this.sharedService.getTranslationsOf('Users') };
        this.titlePage = this.i18n.forum_forum;
        this.placeholderSearch = this.i18n.forum_search_debates;
    }
    // get user info
    getUser() {
        this.sharedService.getUser().subscribe((user) => {
            this.user = user;
            console.log('this.user ', this.user);
        });
    }

    getDisciplineId() {
        this.discipline_external_id = this.router?.url.split('/')[2];
    }

    searchForum(event: any) {
        this.query = event;
        this.getForum();
    }

    getModalOpenStatus(event: any) {
        this.isModalOpen = event;
    }

    onToggle(event) {
        this.filterOpen = event;
    }

    checkFilter(event: any) {
        if (event) {
            this.filter = event;
            this.getForum();
        }
    }

    checkOrder(event: any) {
        if (event) {
            this.order = event;
            this.getForum();
        }
    }

    editForum(thread_text: any, thread_id: any) {
        this.isEditMode = true;
        this.isModalOpen = true;
        this.threadText = thread_text;
        this.forum_post_id = thread_id;
    }

    receiveThread(objectThread: any) {
        if (objectThread.type === 'edit') {
            const threadIndex = this.forums.items.findIndex((thread: any) => thread.id === objectThread.thread.id);
            if (threadIndex !== -1) {
                const plainText = this.htmlToPlainText(objectThread.thread.text);
                this.forums.items[threadIndex].text_sliced = `${plainText.slice(0, 300)}...`;
            }
        }

        if (objectThread.type === 'create') {
            this.forums.items.push(objectThread.thread);
            const plainText = this.htmlToPlainText(objectThread.thread.text);
            objectThread.thread.text_sliced = `${plainText.slice(0, 300)}...`;
        }
    }

    goToForumDetail(id, thread_external_id) {
        sessionStorage.setItem('forum_external_id', thread_external_id);
        sessionStorage.setItem('discipline_external_id', this.discipline_external_id);

        this.router.navigate([`../forum/${id}`], {
            relativeTo: this.route,
        });
    }

    getForum() {
        this.isLoading = true;
        this.forumService
            .getForum(
                'discipline',
                this.discipline_external_id,
                this.activePage,
                this.per_page,
                this.query,
                this.filter,
                this.order
            )
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                (forums) => {
                    // caso não tenha nenhum fórum, seta variavel true para mostrar
                    // mensagem personalizada
                    if (this.query.length === 0 && forums?.items.length === 0) {
                        this.hasntForums = true;
                    }
                    // caso pesquisa não traga nenhum resultado, seta variavel true para mostrar
                    // mensagem personalizada
                    if (this.query.length > 0 && forums?.items.length === 0) {
                        this.hasntResults = true;
                    }
                    this.forums = forums;
                    // Diminui o texto de amostra de cada thread, criando uma nova propriedade chamada text_sliced
                    this.forums?.items.forEach((element) => {
                        const plainText = this.htmlToPlainText(element.text);
                        element.text_sliced = `${plainText.slice(0, 300)}...`;
                    });

                    if (this.forums?.items.length) {
                        this.refreshPagination(this.forums);
                    }
                },
                (err) => {
                    if (err.status === 403) {
                        this.platModalService.toggle('message', err.error.message, 'close');
                    }
                }
            );
    }

    // Converte html para texto púro eliminando tags e demais elementos
    htmlToPlainText(html: any) {
        const temporaryDiv = document.createElement('div');

        temporaryDiv.innerHTML = html;

        return temporaryDiv.textContent || temporaryDiv.innerText || '';
    }

    deleteForum<T extends { content_type: string, attached_type: string, id: number }>(thread: T): void {
        if (!thread) return;

        // Traz as mensagens adequadas para os diferentes cenários do fórum
        const message = this.generateDeleteMessage(thread.content_type, thread.attached_type);

        this.platModalService.toggle('decision', message, {
            forward: () => {
                this.sharedService.modalOpen();
                this.forumService
                    .deleteForum(thread.id)
                    .pipe(
                        finalize(() => {
                            this.sharedService.modalClose();
                        })
                    )
                    .subscribe({
                        next: () => {
                            this.activePage = 1;
                            this.getForum();
                        },
                        error: (err) => {
                            const errorMessage = this.i18n[err.error.error];
                            this.platModalService.toggle('message', errorMessage, 'close');
                        }
                    });
            },
            finally: () => {
                this.forumService.checkForDeleteOnForum(null, true);
                this.platModalService.toggle('decision');
            },
        });
    }

    generateDeleteMessage(contentType: string, attachedType: string): string {
        if (contentType === 'Forum' && attachedType === 'Content') {
            return this.i18n.forum_message_delete_content;
        }

        if (attachedType === 'Content') {
            return this.i18n.forum_message_delete_related_content;
        }

        return this.i18n.forum_message_delete;
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
            query: this.query,
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    goToPage({ activePage }): void {
        this.activePage = activePage;
        this.getForum();
    }
}
