<aside 
    #panelElement 
    class="flex flex-col gap-4 text-18 max-w-max"
    [ngClass]="{ 
        'absolute': isMobile
    }"
>
    <button
        class="bg-customBlue-royal p-2 rounded text-customWhite-default"
        type="button"
        (click)="previousComment()"
        [disabled]="currentCommentIndex < 1"
        [ngClass]="{ 'cursor-not-allowed opacity-50': currentCommentIndex < 1 }"
    >
        <span class="iconify" data-icon="tabler:chevron-up"></span>
    </button>
    <button
        class="bg-customBlue-royal p-2 rounded text-customWhite-default"
        type="button"
        (click)="nextComment()"
        [disabled]="currentCommentIndex >= this.commentsList.length - 1"
        [ngClass]="{
            'cursor-not-allowed opacity-50':
                currentCommentIndex >= this.commentsList.length - 1
        }"
    >
        <span class="iconify" data-icon="mi:chevron-down"></span>
    </button>
</aside>
