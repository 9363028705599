import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Pipe({
    name: 'dateAgo',
    pure: true,
})

export class DateAgoPipe implements PipeTransform {
    currentLanguage: any;
    i18n: any = [];

    constructor(private sharedService: SharedService) {
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Humanize'), ...this.sharedService.getTranslationsOf('Modal') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    transform(value: string): string {
        const currentDate = new Date();
        const pastDate = new Date(value);

        const distanceInSeconds = Math.floor((currentDate.getTime() - pastDate.getTime()) / 1000);

        if (distanceInSeconds < 60) {
            return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInSeconds} ${distanceInSeconds === 1 ? this.i18n.humanize_second : this.i18n.humanize_seconds} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
        }

        const distanceInMinutes = Math.floor(distanceInSeconds / 60);
        if (distanceInMinutes < 60) {
            return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInMinutes} ${distanceInMinutes === 1 ? this.i18n.humanize_minute : this.i18n.humanize_minutes} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
        }

        const distanceInHours = Math.floor(distanceInMinutes / 60);
        if (distanceInHours < 24) {
            return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInHours} ${distanceInHours === 1 ? this.i18n.humanize_hour : this.i18n.humanize_hours} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
        }

        const distanceInDays = Math.floor(distanceInHours / 24);
        if (distanceInDays < 7) {
            return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInDays} ${distanceInDays === 1 ? this.i18n.humanize_day : this.i18n.humanize_days} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
        }

        const distanceInWeeks = Math.floor(distanceInDays / 7);
        if (distanceInWeeks < 4) {
            return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInWeeks} ${distanceInWeeks === 1 ? this.i18n.humanize_week : this.i18n.humanize_weeks} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
        }

        const distanceInMonths = Math.floor(distanceInDays / 30);
        if (distanceInMonths < 12) {
            return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInMonths} ${distanceInMonths === 1 ? this.i18n.humanize_month : this.i18n.humanize_months} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
        }

        const distanceInYears = Math.floor(distanceInDays / 365);
        return `${this.currentLanguage === 'en' ? '' : this.i18n.humanize_time_sentence} ${distanceInYears} ${distanceInYears === 1 ? this.i18n.humanize_year : this.i18n.humanize_yers} ${this.currentLanguage === 'en' ? this.i18n.humanize_time_sentence : ''}`;
    }
}
